
;(function (undefined) {
    'use strict';



    function addClass(el, cssClass) {
        if( el.length ) {
            for(var i = 0; i < el.length; i++) {
                el[i].classList.add(cssClass);
            }
        }
    }

    function removeClass(el, cssClass) {
        if( el.length ) {
            for(var i = 0; i < el.length; i++) {
                el[i].classList.remove(cssClass);
            }
        }
    }

    function htmlToElement(html) {
        var template = document.createElement('template');
        html = html.trim(); // Never return a text node of whitespace as the result
        template.innerHTML = html;
        return template.content.firstChild;
    }

    function checkIsBot(userAgent) {
        userAgent = userAgent || navigator.userAgent;
        var botPattern = "(bot|crawl|lighthouse|headless|spider|findlink|heritrix|scraper|whatsapp|ichiro|coccoc|scrapy|deadlinkchecker|skype|flipboard|postrank|embedly|facebook|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex|curl|urllib|libwww)";
        //var botPattern = "(googlebot\/|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
        var re = new RegExp(botPattern, 'i');
        return re.test(userAgent);
    }

    function setCookie(cookieName, cookieExpireDays) {
        var now = new Date(),
            time = now.getTime(),
            expireTime = time + (cookieExpireDays * 24 * 60 * 60 * 1000);

        now.setTime(expireTime);

        if( cookieExpireDays > 0 ) {
            document.cookie = cookieName+'=1;expires='+now.toGMTString()+';path=/';
        } else {
            document.cookie = cookieName+'=1;path=/';
        }

    }

    function removeCookie(cookieName) {
        document.cookie = cookieName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
    }

    function toggleCookieStatus(checkSettings) {
        if( getCookieStatus(checkSettings.cookieName) ) {
            removeCookie(checkSettings.cookieName);
        } else {
            setCookie(checkSettings.cookieName, checkSettings.cookieExpiresDays);
        }
    }

    function getCookieStatus(cookieName) {
        return !(document.cookie.indexOf(cookieName) === -1);
    }

    function setStatusForElements(checkSettings) {
        var cookieStatus = getCookieStatus(checkSettings.cookieName),
            btns = getToggleBtn(checkSettings);


        /*console.log({
            name: checkSettings.cookieName,
            status: cookieStatus,
            btns: btns
        });*/

        updateButtons(btns, settings.btnActiveClass, cookieStatus);

        if( checkSettings.showHint ) {
            if( cookieStatus ) {
                hideHint(checkSettings);
            } else {
                showHint(checkSettings);
            }
        }
    }

    function updateButtons(btn, cssClass, status) {
        if( btn.length ) {
            if( status ) {
                addClass(btn, cssClass);
            } else {
                removeClass(btn, cssClass);
            }
        }
    }

    function getToggleBtn(checkSettings) {
        return document.querySelectorAll('.'+settings.generalClassPrefix+checkSettings.cssClass+settings.btnToggleClassSuffix);
    }

    function getHintElement(checkSettings, createElement) {
        createElement = createElement || false;

        if( createElement ) {
            createHint(checkSettings);
        }

        return document.querySelectorAll('.'+settings.generalClassPrefix+checkSettings.cssClass+settings.hintClassSuffix);
    }

    function createHint(checkSettings) {
        var hintElem = document.querySelectorAll('.'+settings.generalClassPrefix+checkSettings.cssClass+settings.hintClassSuffix);

        if( !hintElem.length && checkSettings.hintHtml ) {
            var hint = htmlToElement(checkSettings.hintHtml);
            document.body.appendChild(hint);
        }
    }

    function showHint(checkSettings) {
        var hint = getHintElement(checkSettings, true);

        if( hint.length ) {
            addClass(hint, settings.hintActiveClass);
            addClass(html, checkSettings.cssClass+settings.hintClassSuffix+'--'+settings.hintActiveClass);

            if( checkSettings.blockScrollOnVisibleHint ) {
                addClass(html, settings.blockScrollClass);
            }
        }
    }

    function hideHint(checkSettings) {
        var hint = getHintElement(checkSettings);

        if( hint.length ) {
            removeClass(hint, settings.hintActiveClass);
            removeClass(html, checkSettings.cssClass+settings.hintClassSuffix+'--'+settings.hintActiveClass);


            if( checkSettings.blockScrollOnVisibleHint ) {
                removeClass(html, settings.blockScrollClass);
            }

            // aus html entfernen
            hint.forEach(function(el){el.remove();});
        }
    }


    var ageConsentHintHtml = '<div class="age-check-overlay js-age-consent--hint">\n' +
        '    <div class="age-check">\n' +
        '        <div class="age-check--inner">\n' +
        '            <div class="age-check--content">\n' +
        '                <div class="logo age-check--logo">\n' +
        '                    <div class="logo--svg">\n' +
        '                        <svg class="svg-28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153.3 114.2">\n' +
        '                            <title>28</title>\n' +
        '                            <path class="svg-28--path" d="M73.5 83.3c0 3.2-.5 8-1.5 14.4-1 6.5-1.5 11.3-1.5 14.6-3.9 0-11.5-.2-22.6-.7-5.6-.2-9.7-.3-12.2-.3-14.8 0-25.5.3-32.3 1H2.2c-1.5 0-2.2-.5-2.2-1.5s7.1-5.1 21.4-12.3c8.4-4.3 15.3-9.8 20.4-16.6 5.2-6.8 7.8-13.5 7.8-20.3 0-6.1-1.9-11-5.6-14.8-3.7-3.8-8.6-5.7-14.5-5.7-5.5 0-10 1.5-13.5 4.4-3.5 2.9-5.2 5.7-5.2 8.4 0 1.1.3 1.8.8 2.1.6.3 2.6.8 6.2 1.7 3.5.8 5.3 2.7 5.3 5.7 0 2-.7 3.7-2.1 5.2-1.4 1.4-3.1 2.1-5.2 2.1-2.8 0-5.2-1.2-7.3-3.6-2-2.4-3-5.3-3-8.7 0-6.2 2.8-11.4 8.4-15.6 5.6-4.2 12.5-6.3 20.6-6.3 9.2 0 16.6 2.6 22.1 7.8 5.7 5.1 8.4 11 8.4 17.7 0 9.1-5.7 17.5-17 25-2.3 1.5-6.3 3.7-12 6.8-5.7 3.1-9.5 5-11.3 5.7-.9.2-1.4.5-1.5.7 0 .2 1 .4 3.1.5 10.4.3 19.5.4 27.2.2 6.5-.1 10.5-.9 12-2.4s2.8-5.6 3.8-12.3c.3-2.2.6-3.6.9-4.1.3-.5.8-.8 1.6-.8 1.1-.1 1.7.6 1.7 2zM153.3 83.4c0 8.7-3.3 16-10 21.9-6.6 5.9-14.8 8.9-24.5 8.9-10.6 0-19.5-2.8-26.6-8.5C85 100 81.5 93 81.5 84.7c0-9.6 4.1-17.3 12.3-23.3 4.4-3.3 10.6-5.7 18.7-7.3l-.2-.7c-17.9-2.6-26.9-11-26.9-25.3 0-8.1 3-14.8 8.9-20.1s13.6-8 22.7-8 16.6 2.6 22.5 7.9c5.9 5.3 8.8 12 8.8 20.3 0 12.6-8.4 21.1-25.3 25.5l-.2.5c8.7 1.9 15.3 4.6 19.8 8.1 7.1 5.6 10.7 12.7 10.7 21.1zm-15.3-.7c0-8.4-1.8-15.1-5.5-19.9-3.7-4.8-8.7-7.3-15.1-7.3-5.9 0-10.8 2.8-14.7 8.3-3.9 5.5-5.9 12.4-5.9 20.6 0 7.9 2 14.3 6.1 19.2 4.1 4.9 9.3 7.3 15.9 7.3 6.1 0 10.8-2.5 14.2-7.4 3.3-4.9 5-11.9 5-20.8zm-5.3-55c0-16.6-5.1-24.8-15.4-24.8-5.1 0-9 2.3-11.9 6.8s-4.3 10.7-4.3 18.6c0 16 5.5 24 16.5 24 10.1-.1 15.1-8.3 15.1-24.6z"/>\n' +
        '                        </svg>\n' +
        '                    </div>\n' +
        '                    <div class="logo--claim">\n' +
        '                        <strong>#ginforcharity</strong>\n' +
        '                        <span>Dry Gin</span>\n' +
        '                        <span>Handgemacht in Saalbach</span>\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '\n' +
        '                <p><strong>Verantwortunsvolles Trinken ist uns wichtig. Bitte verraten Sie uns Ihr Alter.</strong><br>\n' +
        '                    We support responsive drinking, Please let us know your age.</p>\n' +
        '\n' +
        '                <div class="age-check--buttons">\n' +
        '                    <a href="http://www.rt28.at" rel="noopener" class="age-check--button age-check--button-no">\n' +
        '                        <i class="icon icon-angle-left" aria-hidden="true"></i>\n' +
        '                        <span>\n' +
        '                            <strong>Ich bin unter 18 Jahre alt.</strong><br>\n' +
        '                            I am under 18 years old.\n' +
        '                        </span>\n' +
        '                    </a>\n' +
        '\n' +
        '                    <button class="age-check--button age-check--button-yes js-age-consent--toggle">\n' +
        '                        <i class="icon icon-angle-right" aria-hidden="true"></i>\n' +
        '                        <span>\n' +
        '                            <strong>Ich bin über 18 Jahre alt.</strong><br>\n' +
        '                            I am older than 18 years.\n' +
        '                        </span>\n' +
        '                    </button>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '    </div>\n' +
        '</div>';


    var cookieConsentHintHtml = '<div id="notification-popup-container">' +
        '<div class="notification-popup notification-popup-default notification-cookie js-cookie-consent--hint">\n' +
        '    <div class="notification-popup--content">\n' +
        '        <p>Wir setzen Cookies auf dieser Website ein, um Zugriffe darauf zu analysieren, Ihre bevorzugten Einstellungen zu speichern und Ihre Nutzererfahrung zu optimieren.</p>\n' +
        '    </div>\n' +
        '    <div class="notification-popup--buttons">\n' +
        '        <a href="#" class="notification-popup--button ">Mehr Details</a>\n' +
        '        <button class="notification-popup--button notification-popup--button--highlight js-cookie-consent--toggle">OK</button>\n' +
        '    </div>\n' +
        '</div>' +
        '</div>';




    var isBot = checkIsBot(),
        html = document.querySelectorAll('html'),
        settings = {
            generalClassPrefix: 'js-',
            btnToggleClassSuffix: '--toggle',
            btnActiveClass: 'is-active',
            hintClassSuffix: '--hint',
            hintActiveClass: 'is-active',
            blockScrollClass: 'block-scroll',

            checks: [
                {
                    cookieName: 'ageConsent',
                    showHint: true,
                    cookieExpiresDays: 0,
                    cssClass: 'age-consent',
                    activeOptOutClass: 'is-active',
                    blockScrollOnVisibleHint: true,
                    hintHtml: ageConsentHintHtml
                },
                {
                    cookieName: 'cookieConsent',
                    showHint: true,
                    cookieExpiresDays: 365,
                    cssClass: 'cookie-consent',
                    activeOptOutClass: 'is-active',
                    blockScrollOnVisibleHint: false,
                    hintHtml: cookieConsentHintHtml
                }/*,
                {
                    cookieName: 'analyticsOptOut',
                    cookieExpiresDays: 365 * 3,
                    cssClass: 'analytics-optout',
                    activeOptOutClass: 'is-active'
                }*/
            ]
        };




    settings.checks.forEach(function(currCheck){
        setStatusForElements(currCheck);
        document.addEventListener('click', function (event) {
            var btnClass = '.'+settings.generalClassPrefix+currCheck.cssClass+settings.btnToggleClassSuffix,
                matchSelector = btnClass+', '+btnClass+' *';

            if (event.target.matches(matchSelector)) {
                toggleCookieStatus(currCheck);
                setStatusForElements(currCheck);
            }
        }, false);
    });



}());